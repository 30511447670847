import dayjs from 'dayjs'
import { messageContext } from 'utils/string'

export const translateEvent = (event, i18n = () => {}) => {
  let eventCode = event.event
  let suffix = ''

  // an exception in events code returned by the backend :
  if (eventCode === 'update_training' && event.context.outOfCatalog) {
    eventCode = 'update_training_ooc'
  }

  if (eventCode === 'cancel') {
    suffix = `.${!!event.context.sessionCode ? 'with_session' : 'without_session'}`
  }

  const messageCode = `timeline.trainingwish.event.${eventCode}${suffix}`

  let messageParams = {
    ...event.context,
    creatorFullName: event.creatorFullName,
    createdAt: !!event.createdAt ? dayjs(event.createdAt).format('DD/MM/YYYY hh:mm') : '',
    updatedAt: !!event.updatedAt ? dayjs(event.updatedAt).format('DD/MM/YYYY hh:mm') : '',
  }

  // mail events must contains the name of the mail template sent (eg: 'Mail {templateSlug} sent')
  if (['mail', 'mail_manager', 'mail_collaborator'].includes(eventCode)) {
    messageParams = {
      ...messageParams,
      templateSlug: i18n(`email-template.${messageParams.slug}`),
    }
  }

  if (!i18n(messageCode)) return event.event

  return messageContext(i18n(messageCode), messageParams)
}

export const translateEvents = (events, i18n, timestamp) => events.map((event, index) => {
  const date = event.createdAt
  const formatted = timestamp
    ? dayjs(date).format('DD/MM/YYYY hh:mm') : dayjs(date).format('DD/MM/YYYY')
  return ({
    id: index,
    date: formatted,
    topic: translateEvent(event, i18n),
  })
})

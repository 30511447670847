import * as React from 'react'
import { useRouteLoaderData } from 'react-router-dom'
import type { Auth } from 'context/types'
import { useAuth } from 'context/AuthProvider'
import { useProfile } from 'context/ProfileProvider'

interface Settings {
  mercure: {
    hubUrl: string
    topicUrl: string
  }
}

/**
 * Usage:
 *   const { subscribe } = useMercure()
 *   const eventSource = subscribe(
 *     ['deploy', 'notifications'],
 *     ({ data }) => {
 *       console.log(data.type)
 *     }
 *   )
 *   // ...
 *   eventSource.close()
 */
export const useMercure = () => {
  const settings = useRouteLoaderData('root') as Settings
  const { mercure } = settings
  const { user } = useAuth() as Auth
  const { profile } = useProfile()

  const createEventSource = React.useCallback((topics: string[]) => {
    const url = new URL(mercure.hubUrl)
    const channels = []
    if (user?.id) {
      channels.push('/api/2.0/users')
      channels.push(`/api/2.0/users/${user.id}`)
    }

    if (profile?.id) {
      channels.push(`/api/2.0/profiles/${profile.id}`)
    }

    channels.forEach(channel => {
      topics.forEach(topic => {
        url.searchParams.append(
          'topic',
          `${mercure.topicUrl}${channel}?topic=${encodeURIComponent(`${mercure.topicUrl}${topic}`)}`,
        )
      })
    })

    return new EventSource(url, { withCredentials: true })
  }, [mercure, user?.id, profile?.id])

  const subscribe = React.useCallback((topics: string[], callback: Function) => {
    if (!mercure?.hubUrl) return null // avoid infinite loop when API return 500

    const eventSource = createEventSource(topics)
    eventSource.onmessage = (({ data }) => {
      callback({ data: JSON.parse(data) })
    })

    return eventSource
  }, [createEventSource, mercure])

  return {
    subscribe,
  }
}

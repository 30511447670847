import * as React from 'react'
import { useLocation, useRouteLoaderData } from 'react-router-dom'
import type { Settings } from 'shared/types'
import { useProfile } from 'context/ProfileProvider'
import { useSidebar } from 'context/SidebarProvider'
import { useAuth } from 'context/AuthProvider'
import { PAGES_WITHOUT_SIDEBAR } from 'constants/sidebar'
import type { MenuItem } from './types'
import AdminMenu from './components/AdminMenu'
import Header from './components/Header'
import Menu from './components/Menu'
import { adminItems, getItems } from './items'

export default function SideBar() {
  const settings = useRouteLoaderData('root')
  const { tableauURL, hseEnabled } = settings as Settings
  const { pathname } = useLocation()
  const { hasPermission } = useProfile()
  const { user } = useAuth()
  const { collapsed, floating, navOpen, setFloating, toggleNav, wide } = useSidebar()
  const { perimeter } = user

  const left = React.useMemo(() => wide ? 'left-0' : '-left-[250px]', [wide])
  const overflow = React.useMemo(() => wide ? 'overflow-y-auto' : 'overflow-y-hidden', [wide])

  const items = getItems({ tableauURL, hseEnabled, perimeter })

  // Menu items can have several permissions or no permission key at all...
  const itemHasPermission = React.useCallback((item: MenuItem) => {
    if (!('permissions' in item)) return true
    return (item.permissions || []).some(p => hasPermission(p))
  }, [hasPermission])

  const match = /(\/[a-z]{2})\/([\w]+)/.exec(pathname)
  const excludedPath = match && PAGES_WITHOUT_SIDEBAR.includes(match[2])
  if (excludedPath) return null

  /**
   * Hard to read: retrieve all permissions from admin sub.menu
   * Flat them, filter them and remove duplicates
   */
  let adminPermissions = adminItems.flatMap(item => [
    ...(item.permissions || []),
    ...(item.children || []).flatMap(child => child.permissions || []),
  ])

  // Remove duplicates
  adminPermissions = [...new Set(adminPermissions)]

  const showAdminMenu = adminPermissions.some(p => hasPermission(p))

  const handleMouseEnter = () => {
    if ((collapsed || !navOpen) && !floating) setFloating(true)
  }
  const handleMouseLeave = () => {
    if (!!floating) setFloating(false)
  }

  return (
    <aside
      id="nav-root"
      className={`
        bg-gray-100 flex flex-col fixed top-0 bottom-0
        w-[256px] z-[60] ${left}
        shadow-md shadow-slate-100
        transition-all duration-500 ease
        ${floating ? 'float' : 'not-float'}
      `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Header onMenuClick={toggleNav} />
      <nav
        className={`p-1 pb-20 relative overflow-x-hidden ${overflow}`}
      >
        <Menu items={items.filter(item => itemHasPermission(item))} />
        {showAdminMenu && (
          <>
            <hr className="mx-3 my-1 h-[1px] border-none bg-gray-300" />
            <AdminMenu items={adminItems.filter(item => itemHasPermission(item))} />
          </>
        )}
      </nav>
      <div id="filters-button" />
    </aside>
  )
}

import * as React from 'react'
import type { MultiValue } from 'react-select'
import { useSearchParams } from 'react-router-dom'
import type { SelectValue, Translations } from 'shared/types'
import { useQuery } from 'context/QueryProvider'
import { useI18n } from 'context/I18nProvider'
import { useSidebar } from 'context/SidebarProvider'
import { API_FILTERS } from 'constants/api-v2'
import { formatUser } from 'services/users'

export type UpdateValueType = (key: string, value: string | number | boolean) => void

interface UseFiltersProps {
  updateHistory?: boolean
  urlMapper?: Function
}

interface ApiValue {
  '@id': string
  type: string
  id: string
  translations?: Translations
  name?: string
}

interface DefaultValues {
  [key: string]: ApiValue | string
}

const DefaultUseFiltersArgs: UseFiltersProps = {
  updateHistory: true,
  urlMapper: (filters: any) => filters,
}

/**
 * Label mappers - default is `i18name(opt)`
 */
const mappers = {
  establishments: (opt: ApiValue) => opt.name,
  managers: (opt: ApiValue) => formatUser(opt),
  users: (opt: ApiValue) => formatUser(opt),
}

// Used in Groups
export const getValuesCount = (values: any, key: string) => {
  if (!key) return 0
  if (key in values && Array.isArray(values[key])) {
    return values[key]?.length
  }
  return Number(key in values)
}

/**
 * @see doc/filters.md
 */
export const useFilters = (args: UseFiltersProps = DefaultUseFiltersArgs) => {
  const { updateHistory, urlMapper } = args
  const sidebar = useSidebar()

  const [searchParams]: [URLSearchParams, Function] = useSearchParams()
  const { client } = useQuery()
  const { i18n, i18name } = useI18n()
  const [values, setValues] = React.useState<any>({})
  const [isInitialized, setInitialized] = React.useState<boolean>(false)
  const [url, setUrl] = React.useState(searchParams.toString())

  const mappedLabel = React.useCallback((key: string, opt: ApiValue) => {
    if (key in mappers) return mappers[key as keyof typeof mappers](opt)
    return i18name(opt)
  }, [i18name])

  /**
   * Fetch default values for filters
   */
  const fetchDefaultFilters = React.useCallback(async (params: string) => {
    const resp = await client.get(`${API_FILTERS}?${params}`)

    // Map values from API
    const keys = Object.keys(resp).filter(key => !key.includes('@'))

    const fromApi: DefaultValues = {}
    keys.forEach((k: string) => {
      fromApi[k] = resp[k].map((opt: ApiValue) => ({
        ...opt,
        label: mappedLabel(k, opt),
        value: opt.id,
      }))
    })

    return fromApi
  }, [client, mappedLabel])

  const initValues = React.useCallback((fromApi: object) => {
    // Merge with searchParams and clean up
    let others = {}

    searchParams.forEach((value: string, key) => {
      const isArray = key.includes('[]')
      const newKey = isArray ? key.replace('[]', '') : key
      let vals
      if (isArray) {
        const all = searchParams.getAll(key)
        vals = all.map(v => ({ label: i18n(v), value: v.toString() }))
      } else {
        vals = searchParams.get(key)
      }

      others = {
        ...others,
        [newKey]: vals,
      }
    })

    setValues({ ...others, ...fromApi })
  }, [i18n, searchParams])


  /**
   * __On page load only__ we fetch the name of the filter ids in URL
   */
  React.useEffect(() => {
    async function init() {
      // eslint-disable-next-line max-len
      const shouldFetch = Array.from(searchParams.keys()).some((key: string) => key.includes('[]') && !key.includes('status'))
      // @todo here we need to refactorize
      // @todo the idea is to check inside each filter component the value of the response of fetchDefaultFilters for each queryparams values
      const fromApi = shouldFetch ? await fetchDefaultFilters(searchParams.toString()) : {}
      initValues(fromApi)
    }

    if (updateHistory && !isInitialized) {
      init().then(() => setInitialized(true))
    } else {
      setInitialized(true)
    }

    return () => {}
  }, [updateHistory, fetchDefaultFilters, initValues, searchParams])

  /**
   * /!\ Big performance improvement =)
   * Update `url` without navigation as we only want our state to update for various fetch inside the app
   * @see https://github.com/kentcdodds/kentcdodds.com/blob/main/app/utils/misc.tsx#L296-L323
   */
  const updateUrl = React.useCallback((params: URLSearchParams) => {
    const newParams = params.toString()
    const newUrl = [window.location.pathname, newParams]
      .filter(Boolean)
      .join('?')

    setUrl(newParams)

    if (updateHistory) window.history.replaceState(null, '', newUrl)
  }, [updateHistory])

  /**
   * String or boolean value filter
   */
  const updateValue: UpdateValueType = React.useCallback((key, value) => {
    // Special case for MultiButton which has 3 states (true, false, null)
    setValues((prev: any) => {
      if (value === null || value === undefined) {
        const copy = { ...prev }
        delete copy[key]
        return copy
      }
      return { ...prev, [key]: value }
    })

    const params = new URLSearchParams(url)
    params.delete(key)
    if (value !== null && value !== undefined) params.set(key, value.toString())

    updateUrl(params)
  }, [updateUrl, url])

  /**
   * Update several values at once (for RangePicker, AccreditationBoard, etc...)
   */
  const updateValues = React.useCallback((vals: object) => {
    setValues((prev: any) => ({
      ...prev,
      ...vals,
    }))

    const params = new URLSearchParams(url)
    Object.keys(vals).forEach((key : string) => {
      params.delete(key)
      // @ts-ignore
      if (!!vals[key]) params.set(key, vals[key].toString())
    })

    updateUrl(params)
  }, [updateUrl, url])

  /**
   * For array value filters (AsyncSelect/Select components)
   */
  const updateArrayValue = React.useCallback((key: string, vals: (SelectValue[]|MultiValue<SelectValue>)) => {
    setValues((prev: any) => ({ ...prev, [key]: vals }))

    // In URL we want to add `[]`
    const newKey = `${key}[]`
    const params = new URLSearchParams(url)
    params.delete(newKey)
    vals.forEach((v: SelectValue) => params.append(newKey, v.value?.toString()))

    updateUrl(params)
  }, [updateUrl, url])


  /**
   * For "simple' array value (like Accreditation Board expanded ids)
   */
  const updateSimpleArrayValue = React.useCallback((key: string, vals: number[]|string[]) => {
    setValues((prev: any) => ({ ...prev, [key]: vals }))

    const params = new URLSearchParams(url)
    params.delete(key)
    vals.forEach((v: number|string) => params.append(key, v.toString()))

    updateUrl(params)
  }, [updateUrl, url])

  /**
   * Reset all filters (triggers navigation)
   */
  const reset = React.useCallback(() => {
    setValues({})
    updateUrl(new URLSearchParams(''))
  }, [updateUrl])

  /**
   * Get count for params
   */
  const getCount = React.useCallback((keys: string[]) => (
    keys.reduce((acc, curr) => acc + (getValuesCount(values, curr) || 0), 0)
  ), [values])

  const dependencyValues = React.useCallback((key: string) => {
    const params = new URLSearchParams(url)
    return params.getAll(key)
  }, [url])

  const dependencyUrl = (key: string, name: string): string => (
    dependencyValues(key).map((val: string, i: number) => `${i !== 0 ? '&' : ''}${name}=${val}`).join('')
  )

  // Update filters count for Sidebar
  React.useEffect(() => {
    if (!sidebar?.setFiltersCount) return () => {}

    function getTotalCount() {
      const params = new URLSearchParams(url)
      params.delete('itemsPerPage')
      params.delete('page')
      return params.size
    }
    sidebar?.setFiltersCount(getTotalCount())
    return () => {}
  }, [sidebar, sidebar?.setFiltersCount, url])

  return {
    dependencyValues,
    dependencyUrl,
    fetchDefaultFilters,
    getCount,
    reset,
    setValues,
    updateArrayValue,
    updateSimpleArrayValue,
    updateValue,
    updateValues,
    url: !!urlMapper ? decodeURI(urlMapper(url)) : decodeURI(url),
    urlLegacy: decodeURI(url),
    values,
    isInitialized,
  }
}

import * as React from 'react'
import { Empty } from 'antd'
import { useI18n } from 'context/I18nProvider'
import { useFilters } from 'hooks/filters'
import { useInfiniteSWR } from 'hooks/infinite-swr'
import Button from 'components/UI/Button'
import Section from 'components/UI/Section'
import { API_URL_ACCREDITATION_ACCREDITATIONS } from 'constants/api'
import { messageContext } from 'utils/string'
import Card from './Card'
import AccreditationFilters from './AccreditationFilters'
import FiltersWrapper from './FiltersWrapper'
import TopSearch from './TopSearch'

function Accreditation() {
  const filtersProps = useFilters()
  const { values, updateValue, url: urlFilters } = filtersProps

  const { i18n, i18name } = useI18n()

  const { data, hasNext, next } = useInfiniteSWR(`${API_URL_ACCREDITATION_ACCREDITATIONS}?${urlFilters}`)

  const catalog = data || { nbResults: 0, results: [] }
  const empty = !catalog?.results?.length

  return (
    <div className="catalog relative flex flex-col">
      <div className="grid grid-cols-4 gap-6">
        <TopSearch
          // @TODO Port infinite-swr to TS */}
          // @ts-ignore */}
          total={catalog.nbResults}
          search={'q' in values && typeof values?.q === 'string' ? values.q : ''}
          onChange={(val: string) => updateValue('q', val)}
          placeholder={i18n('catalog.accreditations.search.placeholder')}
          totalText={i18n('catalog.accreditations.available')}
        />
        <FiltersWrapper>
          <AccreditationFilters filtersProps={filtersProps} />
        </FiltersWrapper>
        {!empty && catalog.results.map((item: { id: number, accreditationDomain: string }) => (
          <Card
            key={item.id}
            training={item}
            path={<div>{`${i18name(item.accreditationDomain)}`}</div>}
          />
        ))}
        {empty && (
          <Section className="col-span-4"><Empty /></Section>
        )}
      </div>
      {!empty && hasNext && (
        <Button onClick={next} className="mt-8 self-center">
          {/* @TODO Port infinite-swr to TS */}
          {/* @ts-ignore */}
          {messageContext(i18n('label.infinite.scroll.button'), { nbPerPage: catalog?.nbPerPage })}
        </Button>
      )}
    </div>
  )
}

export default Accreditation

import * as React from 'react'
import type { InputProps, InputRef } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'
import { useI18n } from 'context/I18nProvider'
import Input from 'components/UI/Input'

type SearchInputProps = Omit<InputProps, 'onChange'> & {
  onChange: (txt: string) => void

}

const SearchInput = React.forwardRef<InputRef, SearchInputProps>((props, ref) => {
  const { onChange, ...rest } = props
  const { i18n } = useI18n()

  const debouncedSearch = debounce((text: string) => onChange?.(text), 300)

  // @ts-ignore
  const handleChange = (e: React.ChangeEventHandler<HTMLInputElement>) => debouncedSearch(e.target.value)

  return (
    <Input
      ref={ref}
      addonBefore={<SearchOutlined className="text-lg" />}
      // @ts-ignore
      onChange={handleChange}
      placeholder={i18n('label.searchplaceholder')}
      allowClear
      outline={false}
      {...rest}
    />
  )
})

export default SearchInput

import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Space, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { CalendarFilled, ClockCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import dayjs from 'dayjs'
import type { Translations, TrainingWish } from 'shared/types'
import { useI18n } from 'context/I18nProvider'
import useSWR from 'hooks/swr'
import { capitalize } from 'services/string'
import Spin from 'components/UI/Spin'
import TrainingIcon from 'components/UI/Icons/TrainingIcon'
import { idToIri } from 'services/apiPlatform'
import { API_TRAINING_WISHES } from 'constants/api-v2'
import { TopButton } from './Buttons'
import { Separator, TopCard, TopContent } from './Content'

interface PendingEntry {
  date: string
  hasSpots: boolean
  training: Translations
}
interface SessionEntry {
  date: string
  done: boolean
  training: Translations
}

const userIri = (id: string|undefined) => idToIri('user', id)

function Wishes() {
  const { id } = useParams()
  const { i18name } = useI18n()

  const columns: ColumnsType<TrainingWish> = [
    {
      dataIndex: 'id',
      render: (_: string, record: any) => (
        <Space.Compact direction="vertical">
          <div className="text-[--primary] font-bold line-clamp-1">{capitalize(i18name(record.training))}</div>
          <div className="text-sm text-gray-700">
            {`Demandée le : ${dayjs(record.creationDate).format('DD/MM/YYYY')}`}
          </div>
        </Space.Compact>
      ),
    }, {
      dataIndex: 'duration',
      className: '!px-2',
      width: 100,
      render: (text: string) => <Space><ClockCircleOutlined />{!!text ? `${text} h` : '−'}</Space>,
    },
  ]

  let url = API_TRAINING_WISHES
  url += '?groups[]=training-wishes:list:needs&status[]=allowed&status[]=pending&status[]=disallowed'
  url += `&user=${userIri(id)}&itemsPerPage=3`
  const { data, isValidating } = useSWR(url)

  if (isValidating) return <Spin />

  return (
    <TopCard
      className="w-[30%]"
      title="En demande"
      extra={<TopButton>Voir</TopButton>}
    >
      <Table rowKey="@id" dataSource={data} columns={columns} showHeader={false} pagination={false} />
    </TopCard>
  )
}

function Pending() {
  const { id } = useParams()
  const { i18name } = useI18n()

  const columns: ColumnsType<PendingEntry> = [
    {
      dataIndex: 'hasSpots',
      width: 3,
      className: '!p-4 !pr-0',
      render: (text: boolean) => (
        !!text && <div className="bg-[--primary] w-[3px] h-[34px]" />
      ),
    }, {
      dataIndex: 'title',
      render: (_: string, record: any) => (
        <Space.Compact direction="vertical">
          <div className="text-[--primary] font-bold line-clamp-1">{capitalize(i18name(record.training))}</div>
          <div className="text-sm text-gray-700">
            {!!record.availableSessions ? (
              <Space>
                <ExclamationCircleFilled className="text-orange-600" />
                <div className="text-gray-800 font-bold uppercase cursor-pointer">
                  S&apos;inscrire
                </div>
              </Space>
            ) : (
              <div className="text-[--secondary] italic">Aucune session disponible</div>
            )}
          </div>
        </Space.Compact>
      ),
    }, {
      dataIndex: 'duration',
      className: '!px-2',
      width: 100,
      render: (text: string) => <Space><ClockCircleOutlined />{!!text ? `${text} h` : '−'}</Space>,
    },
  ]

  let url = API_TRAINING_WISHES
  url += '?groups[]=training-wishes:list:training-plan:to-review'
  url += `&user=${userIri(id)}&status[]=to_review&itemsPerPage=3`
  const { data, isValidating } = useSWR(url)

  if (isValidating) return <Spin />

  return (
    <TopCard
      className="w-[30%]"
      title="En attente"
      extra={<TopButton>Voir / M&apos;inscrire</TopButton>}
    >
      <Table rowKey="@id" dataSource={data} columns={columns} showHeader={false} pagination={false} />
    </TopCard>
  )
}


function Session() {
  const { id } = useParams()
  const { i18name } = useI18n()

  const columns: ColumnsType<SessionEntry> = [
    {
      dataIndex: 'done',
      width: 3,
      className: '!p-4 !pr-0',
      render: (text: boolean) => (
        !!text && <div className="bg-[--primary] w-[3px] h-[34px]" />
      ),
    }, {
      dataIndex: 'title',
      render: (_: string, record: any) => (
        <Space.Compact direction="vertical">
          <div className="text-[--primary] font-bold line-clamp-1">{capitalize(i18name(record.training))}</div>
          <div className="text-sm text-gray-700">
            {/* @TODO Evaluation from API ? */}
            {!!record.done ? (
              <Space>
                <ExclamationCircleFilled className="text-orange-600" />
                <div className="text-gray-800 font-bold uppercase cursor-pointer">
                  Evaluer
                </div>
              </Space>
            ) : <div>&nbsp;</div>}
          </div>
        </Space.Compact>
      ),
    }, {
      dataIndex: ['session', 'dateStart'],
      className: '!px-2',
      width: 70,
      render: (text: string) => (
        <Space className="text-gray-700">
          <CalendarFilled />
          {dayjs(text).format('DD/MM/YYYY')}
        </Space>
      ),
    },
  ]

  let url = API_TRAINING_WISHES
  url += '?groups[]=training-wishes:list:training-plan:registered&groups[]=sessions-view'
  url += `&user=${userIri(id)}&status[]=registered&itemsPerPage=3`
  const { data, isValidating } = useSWR(url)

  if (isValidating) return <Spin />

  return (
    <TopCard
      className="w-[30%]"
      title="Prochaines Sessions"
      extra={<TopButton>Voir / Evaluer</TopButton>}
    >
      <Table rowKey="@id" dataSource={data} columns={columns} showHeader={false} pagination={false} />
    </TopCard>
  )
}

export function Trainings() {
  return (
    <TopContent
      icon={<TrainingIcon />}
      title="Mes Formations"
    >
      <div className="w-full flex justify-between">
        <Wishes />
        <Separator />
        <Pending />
        <Separator />
        <Session />
      </div>
    </TopContent>
  )
}

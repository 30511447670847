import * as React from 'react'
import { Badge, Space } from 'antd'
import { BookOutlined, FileTextOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import { useAuth } from 'context/AuthProvider'
import { useI18n } from 'context/I18nProvider'
import Section from 'components/UI/Section'
import { TopButton } from './Buttons'

interface User {
  firstname: string
  lastname: string
}

export function Header() {
  // @TODO Wait for API so we can fetch user
  const { user } = useAuth() as { user: User }
  const { i18n } = useI18n()

  return (
    <Section>
      <div className="flex justify-between items-center mb-2">
        <UserOutlined className="text-white bg-[--primary] text-[3rem] rounded-full p-4 mr-5" />
        <Space direction="vertical">
          <div className="text-xl font-bold">
            {`${user.firstname} ${user.lastname}`}
          </div>
          <div className="text-sm text-gray-600">
            <div className="flex items-center">
              <SolutionOutlined className="text-[--secondary] !text-lg mr-2" />
              Pierre Bafre - Avene Cosmetics - Revel
            </div>
          </div>
          <div className="text-sm text-gray-600">
            <div className="flex items-center">
              <BookOutlined className="text-[--secondary] !text-lg mr-2" />
              En CDI - Cadre depuis le 23/01/2012
            </div>
          </div>
        </Space>
        <div className="grow" />
        <Space direction="vertical" align="end">
          <Space>
            <Badge
              styles={{ indicator: { width: '1.5rem', height: '1.5rem' } }}
              color="rgb(94,242,94)"
            />
            <div className="font-bold">Actif</div>
          </Space>
          <Space>
            <div className="font-bold">Manager :</div>
            <div className="">FENLON Clarisse (4207598)</div>
          </Space>
          <TopButton>
            <Space>
              <FileTextOutlined className="text-white" />
              {i18n('dashboard.user-see-details')}
            </Space>
          </TopButton>
        </Space>
      </div>
    </Section>
  )
}

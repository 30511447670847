import React from 'react'
import PropTypes from 'prop-types'
import { WarningOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import Button from 'components/UI/Button'
import AsyncSelect from 'components/UI/AsyncSelect'
import { API_URL_TRAINING_WISH_PROVISION_USERSEARCH } from 'constants/api'

function UserSelect({ trainingWish, onChange, value, onSubmit, selected }) {
  const { i18n } = useI18n()

  const nbUsers = value ? value.length : 0
  const originalRemaining = (trainingWish && trainingWish.current_provisions) || 0
  const remaining = originalRemaining - nbUsers
  const confirmMessage = i18n('assign_provision_confirm_btn').replace('{X}', nbUsers)
  const tooManyParticipants = remaining < 0
  const canSubmit = nbUsers > 0 && !tooManyParticipants

  const alreadyThere = option => selected.some(rawItem => rawItem.indexOf(option.reference) >= 0)

  return (
    <>
      <div className="w-[70%]">
        <AsyncSelect
          placeholder={i18n('assign_provision_assign_provision_placeholder')}
          url={API_URL_TRAINING_WISH_PROVISION_USERSEARCH.replace('ID', trainingWish.id)}
          mapper={item => ({
            ...item,
            label: `${item.lastName.toUpperCase()} ${item.firstName} (${item.reference})`,
            reference: item.reference,
            value: item.id,
          })}
          onChange={onChange}
          className="eleo-select"
          classNamePrefix="assign-provision"
          isOptionDisabled={alreadyThere}
          isMulti
          isClearable
        />
        <Button className="mt-2" disabled={!canSubmit} onClick={onSubmit}>{confirmMessage}</Button>
      </div>
      {tooManyParticipants && (
        <div className="text-sm text-red-600 ml-2">
          <WarningOutlined /> {i18n('assign_provision_too_many_participants')}
        </div>
      )}
    </>
  )
}

UserSelect.defaultProps = {
  selected: [],
}

UserSelect.propTypes = {
  trainingWish: PropTypes.shape({
    id: PropTypes.number,
    current_provisions: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  onSubmit: PropTypes.func,
  selected: PropTypes.array,
}

export default UserSelect

import * as React from 'react'
import type { SelectValue } from 'shared/types'
import { useI18n } from 'context/I18nProvider'
import AnimationLocales from 'components/Filters/AnimationLocales'
import Categories from 'components/Filters/Categories'
import Organizations from 'components/Filters/Organizations'
import Pathway from 'components/Filters/Pathway'
import TeachingMethods from 'components/Filters/TeachingMethods'
import Themes from 'components/Filters/Themes'
import Switch from 'components/UI/Switch'
import Button from 'components/UI/Button'
import type { TrainingFiltersProps } from './types'

export default function TrainingFilters({ filtersProps }: { filtersProps: TrainingFiltersProps }) {
  const {
    dependencyValues,
    dependencyUrl,
    values,
    reset,
    updateArrayValue,
    updateValue,
  } = filtersProps

  const { i18n } = useI18n()

  return (
    <div className="grid gap-y-4">
      <Categories
        name="theme.category"
        value={values?.['theme.category'] || []}
        onChange={(vals: SelectValue[]) => updateArrayValue('theme.category', vals)}
      />
      <Themes
        name="theme"
        key={dependencyValues('theme.category[]').join(',')}
        value={values?.theme || []}
        onChange={(vals: SelectValue[]) => updateArrayValue('theme', vals)}
        urlParams={`?${dependencyUrl('theme.category[]', 'category[]')}`}
      />
      <TeachingMethods
        value={values?.teachingMethod || []}
        onChange={(vals: SelectValue[]) => updateArrayValue('teachingMethod', vals)}
      />
      <Organizations
        value={values?.['trainers.organization'] || []}
        onChange={(vals: SelectValue[]) => updateArrayValue('trainers.organization', vals)}
        draft={false}
      />
      <AnimationLocales
        value={values?.languages || []}
        onChange={(vals: SelectValue[]) => updateArrayValue('languages', vals)}
      />
      <Pathway
        value={values?.pathway}
        onChange={(val: string) => updateValue('pathway', val)}
      />
      <Switch
        checked={Boolean(values?.restricted === 'true')}
        onChange={(val: boolean) => updateValue('restricted', val ? 'true' : null)}
        labelOn={i18n('catalog.filters.onnomination')}
        labelOnClassName="mr-auto text-gray-800"
      />
      <Switch
        checked={Boolean(values?.certifying === 'true')}
        onChange={(val: boolean) => updateValue('certifying', val ? 'true' : null)}
        labelOn={i18n('label.certifying')}
        labelOnClassName="mr-auto text-gray-800"
      />
      <Button type="link" onClick={reset}>
        {i18n('label.reset')}
      </Button>
    </div>
  )
}

import * as React from 'react'
import { useActionData, useLoaderData, useNavigation, useParams } from 'react-router-dom'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import type { Translations } from 'shared/types'
import { useI18n } from 'context/I18nProvider'
import { useNotification } from 'context/NotificationProvider'
import { capitalize } from 'services/string'
import Spin from 'components/UI/Spin'
import Link from 'components/UI/Link'

interface Training {
  id: number
  translations: Translations
}
interface Session {
  id: number
  code: string
  training: Training
}
interface Document {
  '@id': string
  name: string
  date: string
  session: Session
}

export function Documents() {
  const { documents } = useLoaderData() as { documents: Document[] }
  const navigation = useNavigation()
  const { notifyError } = useNotification()
  const { lang } = useParams()

  const actionData = useActionData() as { error: object | null }
  const { error } = actionData || { error: null }

  if (error) notifyError(error)

  const { i18n, i18name } = useI18n()

  const columns: ColumnsType<Document> = [
    {
      title: 'Libelle',
      dataIndex: 'name',
      width: '30%',
      render: text => (
        <div className="text-[--primary] font-bold">
          {capitalize(text)}
        </div>
      ),
    },
    {
      title: 'Date de depot',
      dataIndex: 'date',
      width: '20%',
      render: text => (
        <div className="text-gray-700">
          {dayjs(text).format('DD/MM/YYYY')}
        </div>
      ),
    },
    {
      title: 'Origine',
      dataIndex: 'session',
      width: '20%',
      render: data => (
        !!data
          ? <Link to={`/${lang}/sessions/${data.id}`}>{data.code}</Link>
          : i18n('documents.from-user')
      ),
    },
    {
      title: 'Stage',
      dataIndex: ['session', 'training'],
      width: '30%',
      render: data => (
        !!data ? <Link to={`/${lang}/catalog/training/${i18name(data)}`}>{i18name(data)}</Link> : '-'
      ),
    },
  ]

  if (navigation.state === 'loading' || navigation.state === 'submitting') return <Spin />

  return (
    <Table
      rowKey="@id"
      dataSource={documents}
      columns={columns}
    />
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import { DownloadOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import BinaryDownloadLink from 'components/Binary/BinaryDownloadLink'

function ExportButton({ href, label, icon, callback, target, className, headers, buttonType }) {
  const { i18n } = useI18n()

  return (
    <BinaryDownloadLink
      className={`font-bold flex items-center px-1 py-2 ${className || ''}`}
      href={href}
      callback={callback}
      target={target}
      headers={headers}
      buttonType={buttonType}
    >
      {icon || <DownloadOutlined className="mr-2" />}
      <div className={className || ''}>{label || i18n('action.export')}</div>
    </BinaryDownloadLink>
  )
}

ExportButton.defaultProps = {
  callback: () => {},
}

ExportButton.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.object,
  callback: PropTypes.func,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  className: PropTypes.string,
  headers: PropTypes.object,
  buttonType: PropTypes.string,
}

export default ExportButton

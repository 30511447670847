import * as React from 'react'
import { useRouteLoaderData } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons'
import type { Settings } from 'shared/types'
import { useI18n } from 'context/I18nProvider'
import { useSidebar } from 'context/SidebarProvider'
import Logo from '../images/logo_eleo.png'

interface HeaderProps {
  onMenuClick: Function
}

export default function Header({ onMenuClick }: HeaderProps) {
  const settings = useRouteLoaderData('root')
  const { version } = settings as Settings
  const { locale } = useI18n()
  const { wide } = useSidebar()

  const width = React.useMemo(() => wide ? 'w-[110px]' : 'w-0', [wide])
  const right = React.useMemo(() => wide ? '' : 'right-[.125rem]', [wide])

  return (
    <div
      className="
        flex items-center px-4 py-5 bg-gray-100 border-solid border-b border-gray-300 border-t-0 border-r-0 border-l-0
      "
    >
      <a
        href={`/${locale}/catalog/training`}
        title={`${version}`}
        className={`${width} overflow-hidden transition-all duration-500 ease`}
      >
        <img src={Logo} alt="Eleo logo" height="35px" />
      </a>
      <div className="grow" />
      <MenuOutlined onClick={() => onMenuClick()} className={`text-[20px] text-gray-400 relative ${right}`} />
    </div>
  )
}

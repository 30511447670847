/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import PropTypes from 'prop-types'
import { Alert, Space } from 'antd'
import Button from 'components/UI/Button'
import { useBlob } from 'hooks/binary'
import { useI18n } from 'context/I18nProvider'


function BinaryDownloadLink({
  href,
  callback = null,
  target = '',
  children,
  hidePending = false,
  headers = null,
  buttonType = 'primary',
  ...props
}) {
  const { downloadBlob } = useBlob()
  const { i18n } = useI18n()
  const [pending, setPending] = React.useState(false)
  const [error, setError] = React.useState()

  const fetchResource = e => {
    setPending(true)
    e.preventDefault()
    downloadBlob(href, target, headers)
      .then(() => {
        if (callback) callback(e)
      })
      .catch(err => setError(err.response?.statusText))
      .finally(() => setPending(false))
  }

  if (pending && !hidePending) {
    return (
      <Button type="link" className="!text-orange-600" disabled>
        {i18n('app.download.pending')}
      </Button>
    )
  }

  return (
    <Space direction="vertical">
      <Button type={buttonType} onClick={fetchResource} {...props}>
        {children}
      </Button>
      {!!error && <Alert type="error" message={error} />}
    </Space>
  )
}

BinaryDownloadLink.propTypes = {
  href: PropTypes.string.isRequired,
  callback: PropTypes.func,
  target: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  hidePending: PropTypes.bool,
  headers: PropTypes.object,
  buttonType: PropTypes.string,
}

BinaryDownloadLink.defaultProps = {
  hidePending: false,
  buttonType: 'link',
}

export default BinaryDownloadLink

import * as React from 'react'
import type { InitialsResponse } from 'hooks/initials'
import { useI18n } from 'context/I18nProvider'
import AsyncSelect from 'components/UI/AsyncSelect'

interface BaseAsyncSelectProps {
  name: string
  url: string
  label: string
  className?: string
  mapper?: (opt: any) => object
  apiPlatform?: boolean
  urlParams?: string
  proxy?: (url: string, output: InitialsResponse) => InitialsResponse
}

export default function BaseAsyncSelect({
  name,
  url,
  urlParams = '',
  label,
  className = '',
  ...props
}: BaseAsyncSelectProps) {
  const { i18n } = useI18n()

  return (
    <div className={className}>
      <AsyncSelect
        id={name}
        placeholder={i18n(label)}
        url={`${url}${urlParams}`}
        isMulti
        {...props}
      />
    </div>
  )
}

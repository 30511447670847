import React from 'react'
import MultiButton from 'components/UI/MultiButton'

export type FilterMultiButtonProps = {
  value: string | null | undefined
  onChange: Function
}

interface BaseMultiButtonProps {
  labels: string[]
}

export default function BaseMultiButton({
  value,
  labels,
  onChange,
}: FilterMultiButtonProps & BaseMultiButtonProps) {
  const handleChange = (val: number) => {
    if (val === 0) onChange(null)
    if (val === 1) onChange('false')
    if (val === 2) onChange('true')
  }

  const intValue = React.useMemo(() => (
    value === 'false' ? 1 : (value === 'true' ? 2 : 0)
  ), [value])

  return (
    <MultiButton
      labels={labels}
      onClick={handleChange}
      // @ts-ignore
      value={intValue}
      btnClassName="py-0 px-2"
    />
  )
}

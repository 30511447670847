import * as React from 'react'
import { Space, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'

interface Entry {
  title: string
  description: string
  status: string
}

const data = [{
  key: 1,
  title: 'Agile - SCRUM',
  description: 'Date de debut: 27/08/2023',
  status: 'Absent',
}, {
  key: 2,
  title: 'PLM C024 Incoming Goods - Performance',
  description: 'Date de debut: 23/01/2023',
  status: 'Present',
}, {
  key: 3,
  title: 'Agile - SCRUM Niveau 2',
  description: 'Date de debut: 12/08/2023',
  status: 'Present',
}]

export function Historical() {
  const columns: ColumnsType<Entry> = [
    {
      dataIndex: 'title',
      render: (_: string, record: any) => (
        <Space.Compact direction="vertical">
          <div className="text-[--primary] font-bold">{record.title}</div>
          <div className="text-sm text-gray-700">{record.description}</div>
        </Space.Compact>
      ),
    }, {
      dataIndex: 'status',
      render: (_: string, record: any) => {
        const color = record.status === 'Present' ? 'text-[--primary]' : 'text-red-500'
        return (
          <div className={`text-center font-bold ${color}`}>{record.status}</div>
        )
      },
    },
  ]

  return (
    <Table dataSource={data} columns={columns} showHeader={false} pagination={false} />
  )
}

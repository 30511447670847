import React from 'react'
import { Descriptions } from 'antd'
import type { DescriptionsProps } from 'antd'
import dayjs from 'dayjs'
import { useAuth } from 'context/AuthProvider'
import { useI18n } from 'context/I18nProvider'
import { useProfile } from 'context/ProfileProvider'
import useSWR from 'hooks/swr'
import Spinner from 'components/Spinner'
import Modal from 'components/UI/Modal'
import PassportDownload from 'components/Accreditations/PassportDownload'
import { API_URL_USERS } from 'constants/api'
import Link from './Link'

interface UserProfileProps {
  id: number
  onClose: () => void
}
interface Auth {
  user: {
    id: number
  }
}

export default function UserProfile({ id, onClose }: UserProfileProps) {
  const { user: loggedUser } = useAuth() as Auth
  const { i18n } = useI18n()
  const { data: user, error } = useSWR(`${API_URL_USERS}/${id}`)
  const { profile, hasPermission } = useProfile()

  function formatGender(gender: string|number) {
    if (gender === '0' || gender === 0 || gender === '') return i18n('gender.women')
    if (gender === '1' || gender === 1) return i18n('gender.men')

    return i18n('gender.other')
  }

  const formatEmail = (email: string|null) => (
    !!email ? <Link to={`mailto:${email}`}>{email}</Link> : '-'
  )

  const formatDate = (date: string|null) => (
    !!date ? dayjs(date).format('DD/MM/YYYY') : '-'
  )

  const formatDateHour = (date: string|null) => (
    !!date ? dayjs(date).format('DD/MM/YYYY hh:mm:ss') : '-'
  )

  if (!user) return null

  const currentUser = loggedUser.id === id

  const contact: DescriptionsProps['items'] = [
    {
      key: 'phone',
      label: i18n('label.phone'),
      children: user.phone,
    }, {
      key: 'email',
      label: i18n('label.email'),
      children: formatEmail(user.email),
    },
  ]

  const informations: DescriptionsProps['items'] = [
    {
      key: 'gender',
      label: i18n('label.gender'),
      children: formatGender(user.gender),
    }, {
      key: 'userName',
      label: i18n('label.username'),
      children: user.username,
    }, {
      key: 'birthdate',
      label: i18n('label.birthdate'),
      children: formatDate(user.birthdate),
    }, {
      key: 'language',
      label: i18n('label.user.language'),
      children: i18n(`locale.${user.language}`),
    }, {
      key: 'degree',
      label: i18n('label.degree'),
      children: user.degree || '-',
    },
  ]

  const organization: DescriptionsProps['items'] = [
    {
      key: 'manager',
      label: i18n('label.manager'),
      children: user.manager?.fullName || '-',
    }, {
      key: 'managerEmail',
      label: i18n('label.manager.email'),
      children: formatEmail(user.manager?.email),
    }, {
      key: 'entryDate',
      label: i18n('label.entry_date'),
      children: formatDate(user.employmentContract?.entryCompany),
    }, {
      key: 'exitDate',
      label: i18n('label.exit_date'),
      children: formatDate(user.employmentContract?.exitCompany),
    }, {
      key: 'organization',
      label: i18n('label.organization'),
      children: user.organization?.name || '-',
    }, {
      key: 'establishment',
      label: i18n('label.establishment'),
      children: user.establishment?.name || '-',
    }, {
      key: 'site',
      label: i18n('label.site'),
      children: user.site?.name || '-',
    }, {
      key: 'initials',
      label: i18n('label.initials'),
      children: user.initials,
    },
  ]
  if (['advanced', 'medium'].includes(profile.userDetails) || currentUser) {
    organization.push({
      key: 'contractType',
      label: i18n('label.contract_type'),
      children: user.employmentContract?.contractType?.name || '-',
    })
  }
  if (profile.userDetails === 'advanced' || currentUser) {
    organization.push({
      key: 'csp',
      label: i18n('label.csp'),
      children: user.employmentContract?.csp?.name || '-',
    })
  }
  organization.push({
    key: 'active',
    label: i18n('label.active'),
    children: user.enabled ? i18n('yes') : i18n('no'),
  })

  if (profile.userDetails === 'advanced' || currentUser) {
    organization.push({
      key: 'rate',
      label: i18n('label.hourly_rate'),
      children: user.employmentContract?.hourlyRate || '-',
    })
  }
  organization.push({
    key: 'trainer',
    label: i18n('label.trainer'),
    children: user.trainer ? i18n('yes') : i18n('no'),
  })

  const additionalData = (user?.additionalDatas || []).map((data: any, i: number) => (
    {
      key: i,
      label: i18n(`userAdditionalData${i + 1}`),
      children: data,
    }
  ))

  const complementary: DescriptionsProps['items'] = [
    {
      key: 'creationDate',
      label: i18n('label.user.creationDate'),
      children: formatDateHour(user.creationDate),
    }, {
      key: 'updateDate',
      label: i18n('label.user.updateDate'),
      children: formatDateHour(user.updateDate),
    }, {
      key: 'lastActivity',
      label: i18n('label.user.lastActivity'),
      children: formatDateHour(user.lastActivity),
    },
  ]

  const props = { labelStyle: { width: '12rem', marginLeft: '1.5rem' } }

  return (
    <Modal onCancel={onClose} width="900px" footer={null}>
      {
        !profile || !user || error
          ? (<Spinner />)
          : (
            <>
              <div
                className={`
                  absolute -top-4 p-4 text-white font-bold rounded-xl
                  bg-gradient-to-r from-[--secondary] to-[--primary]
                `}
              >
                {user?.fullName}
              </div>
              <div className="absolute top-12 right-16">
                {hasPermission('ACCREDITATION_CAN_DOWNLOAD_PASSPORT')
                  ? <PassportDownload userId={id} />
                  : null}
              </div>

              <Descriptions
                className="mt-10"
                title={i18n('profile.section.contact')}
                items={contact}
                column={2}
                {...props}
              />

              {(profile.userDetails === 'advanced' || currentUser) && (
                <Descriptions
                  title={i18n('profile.section.user_informations')}
                  items={informations}
                  column={2}
                  {...props}
                />
              )}

              <Descriptions
                title={i18n('profile.section.organization_informations')}
                items={organization}
                column={2}
                {...props}
              />

              {(profile.userDetails === 'advanced' || currentUser) && (
                <Descriptions
                  title={i18n('profile.section.additional_datas')}
                  items={additionalData}
                  column={2}
                  {...props}
                />
              )}

              <Descriptions
                title={i18n('profile.section.complementary_informations')}
                items={complementary}
                column={2}
                {...props}
              />
            </>
          )
      }
    </Modal>
  )
}

import { redirect } from 'react-router-dom'
import { getCustomEntriesFromFormData } from 'services/session'
import { filterEmptyOptions } from 'services/actions'
import { API_URL_SESSION } from 'constants/api'

export const createSession = client => (
  async ({ params, request }) => {
    const { id, lang } = params

    const data = await request.formData()

    let URL = API_URL_SESSION
    if (id) URL += `/${id}`

    const training = Number(data.get('training')) || null
    const format = data.get('long') ? 'long' : 'standard'
    const sessionType = Number(data.get('session-type')) || null
    const location = Number(data.get('location')) || null
    const language = Number(data.get('language')) || null
    const slotsAvailable = Number(data.get('slots-available')) || 0
    const minSlots = Number(data.get('min-slots')) || 0
    const totalDurationHours = Number(data.get('duration')) || 0
    const comments = data.get('comments') || null
    const inscriptionPeriod = Number(data.get('inscription-period')) || 0
    const teachingMethod = Number(data.get('teaching-method')) || null
    const timezone = data.get('timezone') || null
    const virtualClassUrl = data.get('class-url') || null
    const withdrawalAuth = Boolean(data.get('withdrawal-auth'))
    const withdrawalPeriod = Number(data.get('withdrawal-period')) || null
    const electronicSignature = Boolean(data.get('electronic-signature'))
    let certificateTemplate = Number(data.get('certificate')) || null
    // Why is the backend sending this weird value which we don't want anyway?
    certificateTemplate = certificateTemplate === -1 ? null : certificateTemplate

    // Custom fields (strings only)
    // @see https://gitlab.com/eleo-rh/training/-/issues/8325
    const customEntries = getCustomEntriesFromFormData(data)

    const payload = {
      // Only pass training and format when creating
      // @see https://gitlab.com/eleo-rh/training/-/issues/1747
      ...(id
        ? {}
        : { training, format }),
      sessionType,
      location,
      language,
      slotsAvailable,
      minSlots,
      totalDurationHours,
      certificateTemplate,
      canDownloadCertificateTemplate: !!certificateTemplate,
      comments,
      inscriptionPeriod,
      teachingMethod,
      timezone,
      virtualClassUrl,
      withdrawalAuth,
      ...(withdrawalAuth ? { withdrawalPeriod } : {}),
      sitesAuth: filterEmptyOptions('sites-auth', data),
      csps: filterEmptyOptions('csps', data),
      vintages: filterEmptyOptions('vintages', data),
      electronicSignature,
      customEntries,
    }

    let resp
    try {
      resp = await client.post(URL, payload)
    } catch (e) {
      return { error: e }
    }
    return redirect(`/${lang}/sessions/${resp.id}`)
  }
)

import React from 'react'
import type { SelectValue } from 'shared/types'
import { useI18n } from 'context/I18nProvider'
import Button from 'components/UI/Button'
import Domains from 'components/Filters/Accreditations/Domains'
import type { TrainingFiltersProps } from './types'

interface AccreditationFiltersProps {
  filtersProps: TrainingFiltersProps
}

export default function AccreditationFilters({ filtersProps }: AccreditationFiltersProps) {
  const {
    values,
    reset,
    updateArrayValue,
  } = filtersProps

  const { i18n } = useI18n()

  return (
    <div className="grid gap-y-4">
      <Domains
        value={values?.domains || []}
        onChange={(vals: SelectValue[]) => updateArrayValue('domains', vals)}
      />
      <Button type="link" onClick={reset}> {i18n('label.reinit')}</Button>
    </div>
  )
}

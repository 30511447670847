export function taskToTaskItem(data, i = 0) {
  delete data['@id'] // eslint-disable-line no-param-reassign
  delete data['@type'] // eslint-disable-line no-param-reassign
  const { translations, id, ...rest } = data

  return {
    ...rest,
    id,
    '@type': 'TaskGroupItem',
    position: i + 1,
    task: { id, translations },
  }
}

export const idToIri = (resource, id) => `/api/2.0/${resource}/${id}`

export const iriToId = iri => Number(iri.split('/').pop())

export const getId = payload => iriToId(payload['@id'])

import * as React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Space } from 'antd'
import { ClockCircleFilled, FileFilled, ScheduleFilled, UploadOutlined } from '@ant-design/icons'
import { useModal } from 'react-modal-hook'
import { useAuth } from 'context/AuthProvider'
import { useI18n } from 'context/I18nProvider'
import Page403 from 'components/Page403'
import type { Auth } from './types'
import { Accreditations } from './Accreditations'
import { ButtonWhite } from './Buttons'
import { Content } from './Content'
import { Documents } from './Documents'
import { Header } from './Header'
import { Historical } from './Historical'
import { Trainings } from './Trainings'
import UploadDocumentModal from './UploadDocumentModal'

/**
 * To be able to use the current user `id` in our loader we redirect here
 */
export function RedirectUser() {
  const { user } = useAuth() as Auth

  return <Navigate to={user.id.toString()} />
}

export default function Dashboard() {
  const { user } = useAuth() as Auth
  const { id } = useParams()
  const { i18n } = useI18n()

  const [show, hide] = useModal(() => (
    <UploadDocumentModal onCancel={hide} />
  ), [])

  // User has no right to see Dashboard for other user
  if (user.id !== Number(id)) return <Page403 />

  return (
    <div className="grid grid-cols-2 gap-8">
      <div className="col-span-2">
        <Header />
      </div>

      <div className="col-span-2">
        <Trainings />
      </div>

      <Content
        icon={<ScheduleFilled />}
        title={i18n('label.my.accreditations.hse')}
        extra={<ButtonWhite>Voir</ButtonWhite>}
      >
        <Accreditations />
      </Content>

      <Content
        icon={<ClockCircleFilled />}
        title={i18n('label.my-historical')}
        extra={<ButtonWhite>Voir</ButtonWhite>}
      >
        <Historical />
      </Content>

      <div className="col-span-2 mb-12">
        <Content
          icon={<FileFilled />}
          title={i18n('label.my-documents')}
          extra={(
            <ButtonWhite onClick={show}>
              <Space><UploadOutlined />{i18n('session.participants.upload-document')}</Space>
            </ButtonWhite>
          )}
        >
          <Documents />
        </Content>
      </div>
    </div>
  )
}

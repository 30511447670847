import * as React from 'react'
import { API_ORGANIZATIONS } from 'constants/api-v2'
import BaseAsyncSelect from 'components/Filters/BaseAsyncSelect'

interface OrganizationsValue {
  id: number
  name: string
  draft?: boolean
}

interface OrganizationsProps {
  draft?: boolean
}

export default function Organizations({ draft, ...props }: OrganizationsProps & any) {
  return (
    <BaseAsyncSelect
      name="organizations"
      label="label.training_organization"
      url={`${API_ORGANIZATIONS}?trainingOrganization=true${typeof draft !== 'undefined' ? `&draft=${draft}` : ''}`}
      mapper={(o: OrganizationsValue) => ({ label: o.name, value: o.id, draft: o.draft })}
      apiPlatform
      {...props}
    />
  )
}

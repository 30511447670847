import * as React from 'react'
// import { useParams } from 'react-router-dom'
import { Space, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useI18n } from 'context/I18nProvider'
import useSWR from 'hooks/swr'
import Spin from 'components/UI/Spin'
// import { idToIri } from 'services/apiPlatform'
import { API_CERTIFICATE_WISHES } from 'constants/api-v2'

interface Entry {
  accreditationName: string
  acquired: boolean
  endValidityDate: string
  status: string
}

export function Accreditations() {
  // const { id } = useParams()
  const { i18n } = useI18n()

  const acquiredText = (acquired: boolean) => !!acquired ? i18n('label.acquired') : i18n('label.not.acquired')

  const columns: ColumnsType<Entry> = [
    {
      dataIndex: 'accreditationName',
      render: (text, record) => {
        const { endValidityDate } = record
        const date = !!endValidityDate ? dayjs(record.endValidityDate).format('DD/MM/YYYY') : '−'
        return (
          <Space.Compact direction="vertical">
            <div className="text-[--primary] font-bold">{text}</div>
            <div className="text-sm text-gray-700">
              {`${i18n('label.accreditation.enddate')} : ${date}`}
            </div>
          </Space.Compact>
        )
      },
    }, {
      dataIndex: 'acquired',
      render: value => {
        const color = !!value ? 'text-[--primary]' : 'text-red-500'
        return (
          <div className={`text-center font-bold ${color}`}>{acquiredText(value)}</div>
        )
      },
    }, {
      dataIndex: 'status',
      render: value => {
        // @TODO
        // const color = !!record.validated ? 'text-[--primary]' : 'text-cyan-400'
        const color = 'text-[--primary]'
        return (
          <div className={`text-center font-bold ${color}`}>
            {i18n(`accreditation.status.${value}`)}
          </div>
        )
      },
    },
  ]

  // @TODO Filter by current user
  // const url = `${API_CERTIFICATE_WISHES}?user=${idToIri('user', id)}&page=1&itemsPerPage=3`
  const url = `${API_CERTIFICATE_WISHES}?page=1&itemsPerPage=3`
  const { data, isValidating } = useSWR(url)

  if (isValidating) return <Spin />

  return (
    <Table rowKey="@id" dataSource={data} columns={columns} showHeader={false} pagination={false} />
  )
}

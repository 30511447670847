import QueryClient from 'services/query'
import { API_CUSTOM_FIELDS, API_SESSION_ORDERS, API_SESSIONS } from 'constants/api-v2'
import {
  API_URL_COSTS,
  API_URL_SESSION_ATTENDANCE,
  API_URL_SESSION_COSTS,
  API_URL_SESSION_PARTICIPANT_MAILS,
  API_URL_SESSION_PARTICIPANT_SURVEYS,
} from 'constants/api'

interface LoaderParams {
  params: {
    id?: string
    tab?: string
  }
}

export const loadSession = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let session = null

    try {
      session = await client.get(`${API_SESSIONS}/${id}?groups[]=sessions:view:details`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }
    return session
  }
)

export const loadSessionPeriodForm = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let session = null

    try {
      session = await client.get(`${API_SESSIONS}/${id}?groups[]=sessions:view:period-form`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }
    return session
  }
)

export const loadCustomFields = (client: QueryClient) => (
  async () => {
    let customFields = []

    try {
      customFields = await client.get(API_CUSTOM_FIELDS)
    } catch {
      // pass
    }

    return { customFields: customFields.items ?? customFields }
  }
)

export const loadSessionAndCustomFields = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let session = null
    let customFields = []

    try {
      [session, customFields] = await Promise.all([
        client.get(`${API_SESSIONS}/${id}?groups[]=sessions:view:form`),
        client.get(API_CUSTOM_FIELDS),
      ])
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }

    return { session, customFields: customFields?.items }
  }
)

export const loadTab = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id, tab } = params

    let data
    let error = false

    switch (tab) {
      case 'attendance':
        data = { periods: [], participants: [], trainers: [], attendance: [] }
        try {
          data = await client.get(API_URL_SESSION_ATTENDANCE.replace('ID', id || ''))
        } catch {
          error = true
        }
        break
      case 'costs':
        data = { costs: [], sessionCosts: [] }
        try {
          const [costs, sessionCosts] = await Promise.all([
            client.get(API_URL_COSTS),
            client.get(API_URL_SESSION_COSTS.replace('ID', id || '')),
          ])
          data = { costs, sessionCosts }
        } catch (e: any | unknown) {
          error = true
        }
        break
      case 'mails':
        data = { mails: [], users: [], userMails: [] }
        try {
          data = await client.get(`${API_URL_SESSION_PARTICIPANT_MAILS}/${id}`)
        } catch {
          error = true
        }
        break
      case 'surveys':
        data = { surveys: [], users: [], userSurveys: [] }
        try {
          data = await client.get(`${API_URL_SESSION_PARTICIPANT_SURVEYS}/${id}`)
        } catch {
          error = true
        }
        break
      case 'orders':
        data = []
        try {
          ({ data } = await client.get(`${API_SESSION_ORDERS}?session=${id}`))
        } catch {
          error = true
        }
        break
      default:
        // pass
    }

    return { error, data }
  }
)
